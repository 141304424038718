window.app = window.app || {};
window.app.menuHandler = (function() {
	var inited = false;

	function init() {
		if (inited) {return false;}

		events();

		inited = true;
	}

	function events() {
		$('body')
			.on('click', '.mobile-nav', function() {
				$('.header').addClass('opened');
				$(this).addClass('opened');
			})
			.on('click', '.mobile-nav.opened', function() {
				$('.header').removeClass('opened');
				$(this).removeClass('opened');
			});
	}

	return {
		init: init
	};
})();
