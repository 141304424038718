window.app = window.app || {};
window.app.pageHandler = (function() {

	var inited = false,
		$header,
		$nav,
		wScrollTop = 0,
		wHeight= $(window).height(),
		$w = $(window);

	function init() {
		if (inited) {return false;}

		$header = $('#header');
		$nav = $('#nav');

		generalEvents();

		scrollHandler();
		$w.on('scroll', function() {
			scrollHandler();
		});

		inited = true;
	}

	function closeMenu() {
		$('#nav-trigger').prop('checked', false);
	}

	function scrollUp() {
		TweenMax.to($('html, body'), .5, {scrollTop: 0, ease: Cubic.easeIn});
	}

	function anchorHandler(url) {
		if (!url || url.indexOf('#') < 0 || url.split('#')[1].length === 0) {return false;}
		if (url.split('#')[0] != window.location.href.split('#')[0]) {return false;}

		var hash = '#' + url.split('#')[1],
			$anchor = $(hash + ', [data-anchor="' + hash.slice(1) + '"]');

		if ($anchor.length > 0) {
			setTimeout(function() {
				TweenMax.to($('html, body'), .5, {scrollTop: $anchor.offset().top - 100, ease: Cubic.easeIn});
			}, 500);

			return true;
		} else {
			//TweenMax.to($('html, body'), .5, {scrollTop: 0, ease: Cubic.easeIn});
		}
	}

	function generalEvents() {
		$('body')
		// Scroll To Top
		.on('click', '.scrolltop', scrollUp)
		// Scroll to anchor point
		.on('click', 'a[href^="#"]:not([href="#"])', function(e) {
			var handled = anchorHandler(this.href);
			$('.mobile-nav input').prop('checked', false);
			if (handled) {
				e.stopPropagation();
				e.preventDefault();
				this.blur();
				return false;
			}
			return true;
		})
		// close menu by click outside
		.on('click', '#header > .bg', function(e) {
			closeMenu();
		});
	}

	function scrollHandler() {
		wScrollTop = $(window).scrollTop();

		if (!SMALLSCREEN) {
		
			if (wScrollTop > wHeight / 10) {
				$header.addClass('sticky');
				$nav.addClass('sticky');
			} else {
				$header.removeClass('sticky');
				$nav.removeClass('sticky');
			}
		
		}
	}

	return {
		init: init,
		closeMenu: closeMenu,
		scrollUp: scrollUp
	};

})();
